const getPlatform = (): string | null => {
  const cookieName = 'flowhub.platform'
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(cookieName + '=')) {
      const value = cookie.substring(cookieName.length + 1)
      return value
    }
  }
  return null
}

export const APP_TYPE: 'iOS' | 'electron' | 'browser' | 'windows' | 'android' =
  window.webkit
    ? 'iOS'
    : getPlatform() === 'windows.electron'
    ? 'windows'
    : window.electron
    ? 'electron'
    : getPlatform() === 'android'
    ? 'android'
    : 'browser'
