import styled from 'styled-components/macro'
import { BrandColors } from 'style/BrandColors'
import { CheckboxSvg } from './CheckboxSvg'

const rect = `
  rect {
    fill: ${BrandColors.whiteSmoke};
    stroke-width: 1;
    stroke: ${BrandColors.isabelline};
  }
`

export const Wrap = styled.div<{
  disabled: boolean
  margin?: string
  inline?: boolean
}>(({ disabled, margin = '0 0 1.5rem 0', inline = false }) => {
  const styles = `
    margin: ${margin};
    user-select: none;
    width: fit-content;
    ${inline && 'display: inline-block;'}
  `
  if (disabled) {
    return `
      ${styles}
      ${rect}
    `
  }
  return styles
})

export const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  color: ${BrandColors.darkGray10};
  font-weight: ${({ bold }: { bold: boolean; margin?: string }) =>
    bold ? '500' : 'normal'};
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: ${({ margin }: { bold: boolean; margin?: string }) =>
    margin && margin === '0' ? '0' : '0 0 0.25rem 0.1rem'};
  text-transform: capitalize;
`

export const StyledCheckboxSvg = styled(CheckboxSvg)<{
  checked: boolean
  disabled?: boolean
}>`
  margin-right: 0.5rem;

  path {
    fill: ${({ checked, disabled }) =>
      checked && disabled && `${BrandColors.pastelBlue}`}
`
