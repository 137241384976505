import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const RadioButtonGroupLegend = styled.legend<{ inline?: boolean }>`
  font-family: inherit;
  font-weight: bold;

  ${({ inline }) => (inline ? 'margin: 1rem 0' : 'margin: 0 2rem;')};
  color: ${BrandColors.middleRedPurple};
`

export const RadioButtonGroupInnerWrap = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${({ inline }) =>
    !!inline &&
    ` 
    width: 100%;
    flex-direction: row;
    border-radius: 3px; 
    border: 1px solid ${BrandColors.azureishWhite};
  `}
`

export const RadioButtonGroupWrap = styled.fieldset<{ inline?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  border: none;

  ${({ inline }) =>
    !inline &&
    `
    flex: 1;
    justify-content: space-between;
    margin-top: 2rem; 
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
    min-inline-size: min-content;
  `}

  &.no-border {
    > div {
      border: none;
    }
  }

  &.pointer {
    & label,
    input {
      cursor: pointer;
    }
  }

  &.hide-selected-bkg {
    & div > div:hover,
    div > div:focus-within {
      background-color: unset;
    }
  }

  &.centered {
    width: 100%;
    & div {
      justify-content: center;
    }
  }
`
